import React from 'react';
import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import {
  Flex, Container,
} from '@chakra-ui/react';

import Layout from '../components/layout';
import HtmlBody from '../components/html-body';
import EnquireButton from '../components/enquire-button';
import PageHeading from '../components/page-heading';

type Props = {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        description: string;
        title: string;
        image: {
          childImageSharp: {
            fluid: FluidObject;
          }
        }
      }
    }
  }
}

const AboutPage: React.FC<Props> = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { image, title } = frontmatter;
  return (
    <Layout constrainWidth={false}>
      <Flex
        flexDirection="column"
        height="100%"
        color="white"
        position="relative"
        backgroundImage={image.childImageSharp.fluid.src}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backdropFilter: 'auto',
          backdropBrightness: 0.5,

        }}
      >
        <Container position="relative" flex="1" maxW="2xl" py={12} px={8}>
          <PageHeading text={title} />
          <HtmlBody html={html} />
          <Flex justifyContent="center" mt={8}>
            <EnquireButton text="Get in touch" />
          </Flex>

        </Container>
      </Flex>
    </Layout>
  );
};

export const aboutPageQuery = graphql`
  query AboutQuery {
    markdownRemark(fields: {slug: {regex: "/about/"}}) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
